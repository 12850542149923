ul,
li  {
  list-style: none;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}

label {
  &[for] {
    cursor: pointer;
  }
}

.btn {
  &:focus-visible {
    box-shadow: none !important;
  }
}
