.user {
  border-bottom: 1px solid $gray-300;

  &.disabled {
    .user-info > * {
      color: $gray-400 !important;
    }
  }

  &:not(&.disabled):not(&-selected):hover {
    background-color: $gray-200;
    cursor: pointer;
  }

  &:last-child {
    border-bottom: none;
  }
}
