.bg-gray {
  background-color: $gray-100 !important;
}

.max-h {
  &-250 {
    max-height: 250px;
  }

  &-500 {
    max-height: 500px;
  }
}

.drag-handle {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.hide-dropdown-arrow::after {
  display: none !important;
}
