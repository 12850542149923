.event-modal-select {
  margin-bottom: 20px;

  .ng-select {
    border-color: $gray-200 !important;
    width: 100%;
    max-width: 100% !important;
    margin-left: 0;
    background: #F2F2F2 !important;
    border-radius: 6px !important;
  }

  .ng-select.ng-select-single .ng-select-container {
    height: 45px !important;
    padding-left: 19px !important;
  }

  .type-selected {
    display: flex;
    align-items: center;
    gap: 15px;

    p {
      margin: 0 !important;
      font-size: 16px;
      color: $black !important;
    }
  }

  .ng-select-container {
    &:after {
      position: absolute;
      left: 12px;
      top: 13px;
      width: 30px;
      height: 30px;
      font-size: 31px !important;
      content: "";
      background-repeat: no-repeat;
      background-image: url('../../images/icons/event-search.svg');
      background-size: 54%;
    }

    .ng-input > input {
      padding-left: 32px !important;
      padding-top: 6px !important;
    }
  }


  .ng-has-value:after {
    content: unset;
  }

  .ng-clear-wrapper {
    width: 27px !important;

    .ng-clear {
      display: inline-block;
      font-size: 22px !important;
      line-height: 2 !important;
      pointer-events: none;

    }
  }

  .ng-has-value .ng-arrow-wrapper {
    display: none !important;

  }

  .ng-appearance-outline {
    background: $gray-200 !important;
    border-color: transparent !important;
    color: #0E0C3A !important;
  }

  .ng-select-container {
    background: $gray-200 !important;
    border-color: transparent !important;
    color: #0E0C3A !important;
  }

  .ng-dropdown-panel-items .ng-option {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    color: #0E0C3A !important;
    text-overflow: ellipsis;
    background: $gray-200 !important;
  }

  .ng-dropdown-panel.ng-select-bottom {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    margin-top: 0px;
    border-color: #F2F2F2;
    z-index: 99999 !important;
  }

  .ng-placeholder {
    font-size: 16px !important;
    color: #0E0C3A !important;
    padding-left: 16px !important;
    padding-top: 1px !important;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    color: #0E0C3A !important;
    text-overflow: ellipsis;
    background: #F2F2F2 !important;
  }

  span.ng-option-label.ng-star-inserted {
    font-family: 'Poppins';
    color: #0E0C3A !important;
    font-size: 16px;
    font-weight: 500;
  }

}

.date-and-time {
  .ng-arrow-wrapper {
    display: none !important;
  }

  .ng-appearance-outline {
    max-width: 182px;
    width: 100%;
    height: 50px !important;
    border-color: transparent !important;
    background-color: #f2f2f2 !important;
  }
}

//
//.ng-select.ng-select-single .ng-select-container {
//  height: 45px !important;
//  padding-left: 19px !important;
//}
//
//.course-selection {
//  margin-bottom: 9px !important;
//}
//
//.module-selection {
//  margin-top: 5px !important;
//}
//
//.ng-select-container.ng-appearance-outline.ng-has-value:after {
//  content: unset;
//}
//
//.ng-clear-wrapper {
//  width: 27px !important;
//
//  .ng-clear {
//    display: inline-block;
//    font-size: 22px !important;
//    line-height: 2 !important;
//    pointer-events: none;
//
//  }
//}
//
//.ng-select-container {
//  position: relative;
//
//  &:after {
//    position: absolute;
//    left: 12px;
//    top: 13px;
//    width: 30px;
//    height: 30px;
//    font-size: 31px !important;
//    content: "";
//    background-repeat: no-repeat;
//    background-image: url('../../images/icons/event-search.svg');
//    background-size: 54%;
//  }
//}
//
//.form_invalid {
//  border-color: #f25446 !important;
//
//  .ng-select-container.ng-appearance-outline {
//    border-color: #f25446 !important;
//  }
//
//
//  .ng-placeholder {
//    color: #f25446 !important;
//  }
//
//  .ng-arrow {
//
//    border-color: #f25446 transparent transparent !important;
//  }
//
//}
//
//.required-field {
//  font-family: Poppins;
//  font-size: 13px;
//  font-weight: normal;
//  font-stretch: normal;
//  font-style: normal;
//  line-height: normal;
//  letter-spacing: normal;
//  text-align: left;
//  color: #f25446;
//  position: absolute;
//  width: 154px;
//  bottom: -26px;
//  left: 0;
//}
//
//.ng-select {
//  border-color: #F2F2F2 !important;
//  width: 100%;
//  max-width: 623px;
//  margin-left: 0;
//  background: #F2F2F2 !important;
//  border-radius: 6px !important;
//  //border-bottom-right-radius: 0 !important;
//  //border-bottom-left-radius: 0 !important;
//
//  .ng-select-container .ng-input > input {
//    padding-left: 16px;
//    padding-top: 6px;
//  }
//
//  .ng-value-container {
//    padding-left: 26px;
//
//    .ng-value {
//      white-space: nowrap;
//      overflow: hidden;
//      text-overflow: ellipsis;
//      max-width: 57ch;
//    }
//  }
//
//  .icon-status-- {
//    svg-icon {
//      svg {
//        position: absolute;
//        left: 91% !important;
//        top: 5px !important;
//        width: 20px !important;
//        height: 33px !important;
//      }
//    }
//
//
//    p {
//      font-size: 16px !important;
//      font-family: "Poppins" !important;
//      padding-left: 0px !important;
//      color: #0E0C3A !important;
//    }
//  }
//
//}
//
//.dropdown-item-wrapper {
//  display: flex;
//  flex-direction: row;
//
//
//  label {
//    font-family: "poppins";
//    font-size: 13px;
//    font-weight: bold;
//    margin-right: 0;
//    width: 27%;
//    height: 30px;
//    margin-top: 14px;
//  }
//
//  .search-select-icon {
//    width: 100%;
//    position: relative;
//
//    svg {
//      position: absolute;
//      left: 27px;
//      z-index: 9999 !important;
//      width: 15px;
//      height: 15px;
//      top: 15px;
//    }
//
//  }
//}
//
//.ng-select.ng-select-opened.ng-select-bottom > .ng-select-container {
//  border-bottom-right-radius: 0 !important;
//  border-bottom-left-radius: 0 !important;
//  padding-left: 10px;
//}
//
//.ng-placeholder {
//  font-size: 16px !important;
//  font-family: 'Poppins' !important;
//  padding-left: 0px !important;
//  color: #0E0C3A !important;
//}
//
//.ng-select .ng-select-container .ng-value-container .ng-input > input {
//  font-family: 'poppins' !important;
//  font-size: 15px !important;
//  color: #0E0C3A !important;
//}
//
//.ng-dropdown-panel.ng-select-bottom {
//  border-bottom-right-radius: 0 !important;
//  border-bottom-left-radius: 0 !important;
//  margin-top: 0px;
//  border-color: #F2F2F2;
//  z-index: 99999 !important;
//}
//
//.ng-select-container.ng-appearance-outline {
//  background: #F2F2F2 !important;
//  border-color: transparent !important;
//  color: #0E0C3A !important;
//}
//
//.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
//  top: 5px;
//  left: 0;
//  padding-left: 20px;
//  padding-right: 55px;
//}
//
//.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
//  box-sizing: border-box;
//  cursor: pointer;
//  display: block;
//  white-space: nowrap;
//  overflow: hidden;
//  color: #0E0C3A !important;
//  text-overflow: ellipsis;
//  background: #F2F2F2 !important;
//}
//
//span.ng-option-label.ng-star-inserted {
//  font-family: 'Poppins';
//  color: #0E0C3A !important;
//  font-size: 16px;
//  font-weight: 500;
//}
//
//
//.input--date--picker {
//  box-sizing: border-box;
//  margin: 0;
//  max-width: 182px;
//  width: 100%;
//  height: 50px;
//  border: 1px solid;
//  border-color: transparent;
//  background-color: #F2F2F2 !important;
//  background-repeat: no-repeat;
//  background-size: 0 100%;
//  box-shadow: none !important;
//  transition: box-shadow 150ms ease-in, background-image 150ms ease-in, background-size 150ms ease-in, background-color 150ms ease-in;
//  font-weight: 400;
//  letter-spacing: -0.005em;
//  -webkit-text-size-adjust: 100%;
//  font-kerning: normal;
//  font-size: 14px;
//  line-height: 1em;
//  color: #343741;
//  //border: none;
//  border-radius: 6px !important;
//  padding: 12px 20px;
//  font-family: 'poppins';
//  font-size: 14px;
//  font-weight: 500;
//}
//
//.input--time--picker {
//  box-sizing: border-box;
//  margin: 0;
//  max-width: 182px;
//  width: 100%;
//  height: 50px;
//  border: 1px solid;
//  border-color: transparent;
//  background-color: #F2F2F2 !important;
//  background-repeat: no-repeat;
//  background-size: 0 100%;
//  box-shadow: none !important;
//  transition: box-shadow 150ms ease-in, background-image 150ms ease-in, background-size 150ms ease-in, background-color 150ms ease-in;
//  font-weight: 400;
//  letter-spacing: -0.005em;
//  -webkit-text-size-adjust: 100%;
//  font-kerning: normal;
//  font-size: 14px;
//  line-height: 1em;
//  color: #343741;
//  border-radius: 6px !important;
//  padding: 12px 28px;
//  font-family: 'poppins';
//  font-size: 14px;
//  font-weight: 500;
//
//
//}
//
//.input--date-icon {
//  position: absolute;
//  top: 6px !important;
//  width: 20px;
//  line-height: 36px;
//  border: none;
//  background: transparent;
//}
//
//.custom-date-icon {
//  left: 144px;
//}
//
//
//.ng-select-container.ng-appearance-outline.ng-has-value {
//  .ng-arrow-wrapper {
//    display: none !important;
//  }
//
//  .ng-value-container {
//    padding-left: 0 !important;
//  }
//
//  .ng-value-container .ng-input {
//    padding-left: 0;
//  }
//}
//
////.input--date-icon.inp--delete {
////  cursor: pointer;
////  top: 5px !important;
////  right: 3px !important;
////  padding-left: 0;
////  padding-right: 0.5rem;
////}
////
////.date-remove-icon {
////  right: 50px !important;
////}
