.dp-hidden {
  width: 0;
  margin: 0;
  border: none;
  padding: 0;
}

.ngb-dp-day {
  // Disabled
  &.disabled {
    .custom-day {
      opacity: .5;
    }
  }

  .custom-day {
    text-align: center;
    padding: 0.185rem 0.25rem;
    display: inline-block;
    height: 2rem;
    width: 2rem;
  }
}

.custom-day.focused {
  background-color: $gray-400;
}

.custom-day.range,
.custom-day:hover {
  background-color: $primary;
  color: $white;
}

.custom-day.faded {
  background-color: rgba($primary, 0.5);
}

.quiz-info {
  .dropdown-menu.show {
    width: 234px;
    position: absolute !important;
    inset: 0px 0px 0px auto !important;
    margin: 0px;
    transform: translate(0px, 37px) !important;
    min-height: 403px;
  }
}
