.form {
  &-check {
    &-input {
      border: 2px solid $gray-500;
      cursor: pointer;

      // Radio
      &[type="radio"] {
        &:checked,
        &:focus,
        &:active,
        &:active:focus {
          border-color: $info;

          &[type="radio"] {
            background-image: none;
          }

          background-color: $success;
          box-shadow: inset 0 0 0 3px $white,
          inset 0 0 0 3px $success, 0 0 0;

          &[disabled] {
            background-color: $white;
            border-color: $gray-500;
          }
        }
      }

      // Switch
      &[role="switch"] {
        $baseSwitchColor: $gray-500;
        $baseSwitchBgImage: "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{svg-color($baseSwitchColor)}'/></svg>";
        border-color: $baseSwitchColor;
        margin-left: 0;
        float: none;

        background-image: url($baseSwitchBgImage);
        background-color: transparent;

        &:active,
        &:focus {
          background-image: url($baseSwitchBgImage);
          background-color: inherit;
          border-color: $baseSwitchColor;
        }

        &:checked {
          $switchCheckedColor: $success;
          background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{svg-color($switchCheckedColor)}'/></svg>");
          border-color: $success;

          + label {
            color: $success;
          }
        }
      }
    }
  }
}
