.nav-tabs {
  border-bottom: none;

  .nav-link {
    border: none;
    border-bottom-width: $nav-tabs-border-width;
    border-bottom-style: solid;
    margin-bottom: -1px;

    &[disabled]:not(.active) {
      color: $nav-tabs-border-color;
      border-color: $nav-tabs-border-color;
      cursor: not-allowed;
    }
  }
}
