@import "/design-system-ui/scss/abstracts/variables";

// Accordion
$accordion-button-active-bg: $gray-200;
$accordion-button-active-color: $gray-900;

/*
  Account
 */

// Header
$account-header-height: 55px !default;
$account-header-bg: $white !default;
$account-header-search-bg: $gray-100 !default;
// Sidebar
$account-sidebar-bg: $gray-100 !default;
$account-sidebar-width: 250px !default;
$account-sidebar-border-right: 1px solid $gray-300 !default;
$account-sidebar-item-hover: $gray-300 !default;
