.svg--icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.1);
  margin: 3px 0 0 8px;
  flex: none;
}


.svg--icon {
  border-radius: 9px !important;
  width: 28px;
  height: 28px;
  margin-left: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;

  svg.align-middle {
    width: 10px !important;
    font-size: 10px !important;
  }

}


.svg--icon.small {
  width: 20px;
  height: 20px;
}

.svg--icon[data-icon=content-video] {
  background: #2653F6;
  color: #fff;
  fill: #fff;
}

.svg--icon[data-icon=content-quiz] {
  background: #0af;
  color: #fff;
  fill: #fff;
}

.svg--icon[data-icon=content-htmlpage] {
  background: #FFAD3B;
  color: #2a2a2a;
  fill: #2a2a2a;
}

app-svg.visible {
  color: #66D639;
}

app-svg.not-visible {
  color: #F24C3D;
}


.svg--icon[data-icon=content-weburl] {
  background: #9C59DF;
  color: #fff;
  fill: #fff;
}

.svg--icon[data-icon=content-file] {
  background: #00BCA6;
  color: #fff;
  fill: #fff;
}
