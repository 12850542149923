.add-user-modal {
  .modal-content {
    @extend .overflow-hidden;
  }

  .btn-close {
    top: 10px;
    right: 10px;
  }

  .users {
    min-height: 360px;
    max-height: 360px;

    &.users-selected {
      max-height: 420px;
    }
  }

  &-right {
    background-color: rgba($primary, .1);
  }
}
