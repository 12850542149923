.accordion {
  &-custom-header {
    .btn-link {
      color: $gray-900;
      text-decoration: none;

      &:hover,
      &:focus {
        color: inherit;
      }
    }

    &:hover {
      background-color: $gray-300;
    }
  }

  &-button {
    &:after {
      content: none;
    }
  }

  &-body {
    background-color: $accordion-button-active-bg;
  }
}
