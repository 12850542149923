.pagination {
  .page-item {
    .page-link {
      border-radius: $pagination-border-radius;
      width: 35px;
      @extend #{'.text-center', '.ps-0', '.pe-0', '.ms-1'};
    }

    &:not(.active) {
      .page-link {
        border-color: transparent;
      }
    }
  }
}
