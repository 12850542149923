// PRE CONFIGS
$enable-shadows: false !default;
// Transitions
$transition-base: all .2s ease-in-out !default;
$transition-fade: opacity .15s linear !default;
$transition-collapse: height .35s ease !default;
$transition-collapse-width: width .35s ease !default;
// Box shadow
$btn-box-shadow: none !default;
$btn-focus-box-shadow: none !default;
$btn-active-box-shadow: none !default;
$btn-close-focus-shadow: none !default;
$input-btn-focus-box-shadow: none !default;
$input-box-shadow: none !default;
$input-focus-box-shadow: none !default;
$form-check-input-focus-box-shadow: none !default;
$form-select-box-shadow: none !default;
$form-select-focus-box-shadow: none !default;
$pagination-focus-box-shadow: none !default;

// Gray colors
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;

// Colors
$white: #fff !default;
$black: #000 !default;
$blue: #0036ff !default;
$indigo: #5611ff !default;
$purple: #9379fe !default;
$red: #f24c3d !default;
$orange: #ffa600 !default;
$green: #66d639 !default;
$cyan: #01c9ff !default;


// Theme colors
$primary: $cyan !default;
$secondary: $purple !default;
$success: $green !default;
$info: $blue !default;
$warning: $orange !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-900 !default;

// Body text color
$body-color: $gray-700 !default;

// Font
$font-family-base: 'Poppins', sans-serif !default;
$font-size-base: 0.875rem !default;

// Grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
) !default;

// Form
$form-feedback-icon-valid: '' !default;
$form-feedback-icon-invalid: '' !default;

// Button
$btn-border-radius: 22px !default;
$btn-border-radius-sm: 22px !default;
$btn-border-radius-lg: 22px !default;

// Alert
$alert-border-radius: 6px !default;
$alert-border-width: 0px !default;
$alert-border-left-width: 6px !default;

// Breadcrumb
$breadcrumb-divider: quote(">") !default;

// Table
$table-th-color: $gray-500 !default;
$table-th-font-size: 0.8125rem !default;
$table-th-font-weight: 600 !default;
$table-cell-vertical-align: middle !default;

// Nav
$nav-link-color: $gray-400 !default;
$nav-link-hover-color: $primary !default;

// Tab
$nav-tabs-border-width: 2px !default;
$nav-tabs-border-color: $gray-400 !default;
$nav-tabs-link-active-border-color: $primary !default;
$nav-tabs-link-hover-border-color: $primary !default;
$nav-tabs-link-active-color: $primary !default;

// Card
$card-border-color: $gray-400 !default;

// Pagination
$pagination-color: $body-color !default;
$pagination-bg: transparent !default;
$pagination-border-radius: 18px !default;
$pagination-active-color: $white !default;
$pagination-active-bg: $primary !default;
$pagination-active-border-color: $pagination-active-bg !default;
$pagination-hover-color: $pagination-active-color !default;
$pagination-hover-bg: $pagination-active-bg !default;
$pagination-hover-border-color: $pagination-active-border-color !default;
$pagination-focus-color: $pagination-active-color !default;
$pagination-focus-bg: $pagination-active-bg !default;
