/* You can add global styles to this file, and also import other style files */

// Abstracts
@import "abstracts/functions";
@import "abstracts/mixins";
@import "abstracts/variables";
@import "abstracts/utilities";

// Vendors
@import "/design-system-ui/scss/design-system-ui";
@import "@ng-select/ng-select/themes/default.theme.css";
@import "dragula/dist/dragula.min.css";

// Components
@import "components/form";
@import "components/calendar";
@import "components/svg-buttons";
@import "components/event-ng-select";
@import "components/date-picker";
@import "components/add-user-modal";
@import "components/user-card";
@import "components/tooltip";
@import "components/accordion";

