.table {
  thead {
    tr > th {
      font-size: $table-th-font-size;
      color: $table-th-color;
      @extend .text-uppercase;
    }
  }

  &-sort {
    &-icon {
      margin-top: -5px;
      cursor: pointer;

      &.active {
        color: $primary;
      }
    }
  }
}

