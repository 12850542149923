.alert {
  border-left: $alert-border-left-width solid transparent;

  @each $key, $value in $theme-colors {
    &-#{$key} {
   //   @include alert-variant(rgba($value, .2), transparent, $value);
      border-left-color: $value;
    }
  }
}
