.form {
  &-label {
    cursor: pointer;
    @extend #{'.fw-semibold', '.text-capitalize'};
  }

  &-control {
    &-gray {
      background-color: $gray-200;

      &:focus {
        background-color: $white;
      }
    }

    &.is-invalid {
      &:focus {
        @extend .shadow-none;
      }
    }

    .ng-submitted &.ng-invalid {
      @extend .is-invalid;
    }
  }

  &-inline {
    @extend .position-relative;

    .form-control {
      padding-right: $input-btn-padding-x + 3.5rem;
    }

    &-limiter,
    &-icon {
      @extend #{'.position-absolute'};
      right: $input-btn-padding-x;
      top: $input-btn-padding-y;
    }

    &-limiter {
      background-color: $gray-300;
      font-size: $font-size-base - 0.250rem;
      border-radius: $border-radius;
      @extend .p-1;
    }
  }
}
