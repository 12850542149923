@use "abstracts/functions" as f;

@use "abstracts/variables" as v;

// Calendar Toolbar styling monthly view
.fc-toolbar-chunk {
  &:last-child {
    left: -48px !important;
    position: relative !important;
  }
}

.fc-toolbar-title {
  //font-size: map-get(v.$font-size, leap) !important;
  font-size: 17px !important;
  font-weight: 600 !important;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 20px !important;
}

.fc-prev-button {
  border-top-left-radius: 200px !important;
  border-bottom-left-radius: 200px !important;
  background: transparent !important;
  border: 1px solid $primary !important;
  color: $primary !important;
  border-right: none !important;
}


.fc-today-button {
  background: $primary !important;
  color: $black !important;
  text-transform: capitalize !important;
  font-weight: 500 !important;
  border: none !important;
}

.fc-next-button {
  border-top-right-radius: 200px !important;
  border-bottom-right-radius: 200px !important;
  background: transparent !important;
  border: 1px solid $primary !important;
  color: $primary !important;
  border-left: none !important;
}

.fc-timeGridWeek-button.fc-button.fc-button-primary {
  background: $primary !important;
  text-transform: capitalize !important;
  padding: 8px 16px !important;
  border-top-left-radius: 200px !important;
  border-bottom-left-radius: 200px !important;
  border-color: $primary !important;
}

.fc-button-active {
  background: $secondary !important;
}

.fc-button-group .fc-dayGridMonth-button {
  border-top-right-radius: 200px !important;
  border-bottom-right-radius: 200px !important;
  background: $secondary !important;;
  border-color: $secondary !important;
}

//Calendar body styling

.fc-dayGridMonth-view .fc-daygrid-day-frame {
  position: relative;
  min-height: 171px !important;
  max-height: 171px !important;
}

.fc-theme-standard .fc-scrollgrid {
  border: transparent !important;
}

.fc-col-header {
  tr > th {
    border: transparent !important;
  }
}

.fc .fc-timegrid-divider {
  padding: 0 !important;
}

.fc .fc-cell-shaded, .fc .fc-day-disabled {
  background: transparent !important;
  display: none !important;
}

th > .fc-scroller-harness .fc-scroller {
  overflow: hidden !important;
}

.fc-timeGridWeek-view {
  tr:first-child .fc-scroller {
    overflow: hidden !important;
  }
}


.fc .fc-scrollgrid-section-footer > *, .fc .fc-scrollgrid-section-header > * {
  border-bottom-width: 0;
  border-right: transparent !important;
}

tr:first-child > td {
  border-top: 1px solid #dddd !important;
}

.fc-dayGridMonth-view {
  a.fc-daygrid-event.fc-daygrid-block-event.fc-h-event.fc-event.fc-event-start.fc-event-end.fc-event-past {
    background: transparent !important;
  }

  tr td:first-child {
    border-left: 1px solid #ddd !important;
  }
}

tr.fc-scrollgrid-section.fc-scrollgrid-section-header {
  position: relative;
  top: -8px;
}


.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 36px !important;
}

.fc .fc-toolbar {
  @media (max-width: 575.99px) {
    & {
      flex-direction: column;
    }

    .fc-toolbar-chunk {
      margin-bottom: 10px;

      &:last-child {
        left: 0 !important;
      }
    }
  }
}

.fc .fc-col-header-cell-cushion {
  color: $black !important;
}

a.fc-daygrid-more-link.fc-more-link {
  color: $black !important;
  font-size: 13px;
}

.fc .fc-daygrid-day-number {
  color: $black !important;
}

// Calendar events styling

.fc-event-title {
  color: $black !important;
  font-family: $font-family-base;
  font-size: 14px;
  font-weight: 600 !important;
}

.fc-daygrid-dot-event.fc-event {
  width: 91% !important;
  margin-bottom: 12px;

  .fc-daygrid-event-dot {
    margin: 0 6px 0px -2px !important;
    height: 10px !important;
    box-sizing: content-box;
    border-radius: 0 !important;
  }

  .cri.event-start-time {

    margin-right: 5px;
    margin-left: -3px;
    position: absolute;
    bottom: -20px;
    right: 0;
    color: $black;
    font-size: 14px;
  }


}

//Announcements events
.fc-h-event {
  width: 91% !important;
  margin-left: 0 !important;
  margin-bottom: 21px !important;

  background: transparent !important;
  border-color: transparent !important;

  .fc-event-main {
    display: flex;
    flex-direction: row;
    align-items: center;
  }


  .fc-daygrid-event-dot {
    margin: 0 6px 0px -2px !important;
    height: 10px !important;
    box-sizing: content-box;
    border-radius: 0 !important;
  }

  .cri.event-start-time {
    font-weight: 600;

  }

  .allDayEventBg {
    color: $purple !important;
    background-color: rgba(0, 54, 255, 0.2);
    //font-size: map-get(v.$font-size, micro-small) !important;
    //font-weight: map-get(v.$font-weight, semi-bold) !important;
    border-radius: 16px;
    padding-left: 7px;
    padding-right: 7px;
    height: 16px;
    margin-bottom: 3px;
    font-size: 10px;
    font-weight: 600;
    margin-right: 5px;
    margin-left: -3px;
    position: absolute;
    bottom: -20px;
    right: 0;
  }

  .dueDate {
    background-color: rgba(242, 84, 70, 0.2);
    border-radius: 16px;
    padding-left: 7px;
    padding-right: 7px;
    height: 18px;
    margin-bottom: 3px;
    font-size: 10px;
    font-weight: 600;
    color: $danger !important;

    margin-right: 5px;
    margin-left: -3px;
    position: absolute;
    bottom: -23px;
    right: 0;
  }
}

// Calendar styling weekly view
.fc-timeGridWeek-view tr:last-child > td {
  border-top: none !important;
  border-bottom: none !important;
  position: relative;
}

.announcement-week-view {
  display: flex;
  //color: f.get-color(c.$color-black);
  gap: 15px;
  padding: 10px;
  width: 91% !important;
  overflow: hidden;
  align-items: center;


  span {
    color: $black !important;
    font-size: 15px;
  }

  //font-size: map-get(v.$font-size, body) !important;
  //font-weight: map-get(v.$font-weight, regular) !important;
}

.fc-timeGridWeek-view .fc-daygrid-day-frame {
  border-bottom: 1px solid #ddd;
  min-height: 100% !important;
  max-height: 136px !important;
}

.fc-view-harness.fc-view-harness-active {
  height: 1062px !important;
  max-height: 1062px !important;
}

.fc-v-event .fc-event-title {
  top: 0;
  bottom: 0;
  max-height: 100%;
  overflow: hidden;
  color: #fff !important;

}

.fc-timeGridWeek-view {
  tr:first-child > td {
    border-top: none !important;
    border-bottom: none !important;
  }

  tr:last-child > td {
    border-top: none !important;
    border-bottom: none !important;
    position: relative;
  }


  .fc-daygrid-day-events {
    margin-bottom: 1em;
    position: relative;

    &:after {
      position: absolute;
      left: -1px;
      top: -1px;
      width: 110%;
      height: 1px;
      background: #ddd;
      content: '';
      z-index: 99999 !important;
    }

    &:before {
      position: absolute;
      left: -1px;
      bottom: -16px;
      width: 110%;
      height: 1px;
      //    background: #ddd;
      content: "";
      z-index: 99999 !important;
    }
  }


  .fc-daygrid-day-frame {
    border-bottom: 1px solid #ddd;
  }

  .fc-event-time {
    display: none !important;
  }

  .fc-timegrid-slot-label {
    vertical-align: middle;
    border-top: 1px solid #fff;
  }

  .fc-timegrid-slot-minor {
    border-top-style: none !important;
  }

  .fc-timegrid-axis-cushion {
    display: none !important;
  }

}

.fc-theme-standard .fc-popover {
  z-index: 1 !important;
}

.event-background-custom {

  border: 1px solid #3788d8 !important;
}

.fc-timeGridWeek-view {
  .fc-daygrid-event {
    margin-bottom: 0 !important;
  }
}

button.fc-timeGridWeek-button.fc-button.fc-button-primary.fc-button-active {
  background: $purple !important;
  border-color: $purple !important;
}

button.fc-dayGridMonth-button.fc-button.fc-button-primary.fc-button-active {
  background: $purple !important;
  border-color: $purple !important;
}

button.fc-dayGridMonth-button.fc-button.fc-button-primary {
  background: $primary !important;
  border-color: $primary !important;
}

.fc-theme-standard .fc-popover {
  z-index: 9 !important;
}
